// src/Home.js

import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './App.css';

import Modal from './Modal';

const Input = forwardRef(({ title, message, onSave, canClose }, ref) => {
  const modal = useRef();
  const [value, setValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  // Expose the function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    open: (e) => { 
      console.log(e);
      setDefaultValue(e); 
      modal.current.open(); }
  }));

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const close = (e) => {
    modal.current.close(); 
    onSave(e);
    setValue(null);
  }

  return (
      <Modal size="small" ref={modal} canClose={canClose}>
        <h2 style={{paddingTop: 0}}>{title}</h2>
        {
          message
        }
        <input type="text" value={value ?? defaultValue} onChange={onChange} />
        <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
          <button onClick={() => { close(value) }}>Save</button>
          <button onClick={() => { close(null) }}>Cancel</button>
        </div>
      </Modal>
  );
});

export default Input;
