import React, { useState, useEffect } from 'react';
import './MatchesList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import useAxios from './useAxios';

const MatchesList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [matches, setMatches] = useState([]);

    useEffect(() => {
      axios.get('matches', setMatches);
    }, []);

  return (
    <div className="matches-container">
      <h2>Matches</h2>
      <table className="matches-table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Date/Time</th>
                <th>Home</th>
                <th>Away</th>
                <th>Compitition</th>
                <th>Table</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/match/new')}>New</button>
                </td>
            </tr>
            {matches.map((match) => (
            <tr key={match.id}>
                <td>{match.name}</td>
                <td>{moment(match.dateTime).format('DD MMM YY HH:mm')}</td>
                <td>{match.home.player.name}</td>
                <td>{match.away.player.name}</td>
                <td>{match.competition?.name}</td>
                <td>{match.table?.name}</td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/match/' + match.id)}>Edit</button>
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchesList;
