import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';

import './CompetitionRoundRobin.css'; // Import the updated styles for the form
import useAxios from '../../useAxios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';

const CompetitionRoundRobin = forwardRef(({ competition }, ref) => {

  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const [players, setPlayers] = useState(null);
  const [playerToAdd, setPlayerToAdd] = useState(null);
  const [roundRobin, setRoundRobin] = useState(null);

  const [scoreSystems, setScoreSystems] = useState([{id: "winner1draw0", name: "Winner +1 / Draw +0"}, {id: "winner3draw1", name: "Winner +3 / Draw +1"}, {id: "frame", name: "Points each frame"}]);

  useEffect(() => {
    axios.get('players', setPlayers)
    axios.get('competitionroundrobin/'+(competition.id??"new"), setRoundRobin)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoundRobin((prevcomp) => ({ ...prevcomp, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setRoundRobin((prevcomp) => ({ ...prevcomp, [name]: checked }));
  };

  const handleDateTimeChange = (datetime) => {
    setRoundRobin((prevcomp) => ({ ...prevcomp, dateTime: datetime }));
  };

  const handleScoreSystemChange = (system) => {
    setRoundRobin((prevcomp) => ({ ...prevcomp, scoreSystem: system }));
  };

  const addPlayer = () => {
    setRoundRobin((prevcomp) => ({ ...prevcomp, players: [...prevcomp.players, playerToAdd]}));
    setPlayerToAdd(null);
  };

  const removePlayer = (p) => {
    setRoundRobin((prevcomp) => ({ ...prevcomp, players: prevcomp.players.filter(x => x.id !== p.id)}));
  };
  
  const handleOnSave = (competition, next) => {
    roundRobin.competitionId = competition.id;
    axios.post('competitionroundrobin/' + competition.id, roundRobin, next);
  };

  // Expose the function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    onSave: handleOnSave,
  }));

  if (roundRobin === null || players === null)
  {
    return <h1>Loading</h1>;
  }

  return (
        <div className="edit-roundrobin-grid">
          <div className="col1">
            <label>Race To:</label>
            <br />
            <input
                name="raceTo"
                type="number"
                value={roundRobin.raceTo}
                onChange={handleChange}
                min={0}  // Optional: Set minimum value
                max={100}  // Optional: Set maximum value
              />
            <br />
            <br />
            <label>Match Time Limit:</label>
            <br />
            <input
                name="matchTimeLimit"
                type="number"
                value={roundRobin.matchTimeLimit}
                onChange={handleChange}
                min={0}  // Optional: Set minimum value
                max={300}  // Optional: Set maximum value
              />
            <br />
            <br />
            <label>Handicapped:</label>
            <br />
            <input
                name="handicapped"
                type="checkbox"
                value={roundRobin.handicapped}
                onChange={handleCheckboxChange}
              />
            <br />
            <br />
            <label>Number Of Groups:</label>
            <br />
            <input
                name="numberOfGroups"
                type="number"
                value={roundRobin.numberOfGroups}
                onChange={handleChange}
                min={0}  // Optional: Set minimum value
                max={4}  // Optional: Set maximum value
              />
            <br />
            <br />
            <label>Score System:</label>
            <Select
              name="scoreSystem"
              value={roundRobin.scoreSystem}
              onChange={(option) => handleScoreSystemChange(option)}
              options={scoreSystems}
              getOptionValue={(option) => option.id}  // Specify the property used for value
              getOptionLabel={(option) => option.name}  // Specify the property used for label
          isSearchable={false} />
          <br />
          <label>Players:</label>
          <Select
            value={playerToAdd}
            onChange={(option) => setPlayerToAdd(option)}
            options={players.filter(x => roundRobin.players.some(y => y.name === x.name) === false)}
            isClearable={true}
            placeholder="Select a player..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          />
          <button type="button" onClick={addPlayer} disabled={playerToAdd === null}>
            Add
          </button>
          {roundRobin.players.map(x => { return (
            <div style={{height:"50px"}} key={x.id}>{x.name} ({x.handicap}) <a className="small-cross" onClick={() => removePlayer(x)}>x</a></div>
          )})}
          </div>
        </div>
  );
});

export default CompetitionRoundRobin;
