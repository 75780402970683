// src/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { useNavigate, useParams } from 'react-router-dom';

import OrderDetails from './OrderDetails';

import useAxios from './useAxios';
import Modal from './Modal';

import moment from 'moment';

import Confirm from './Confirm';

import QRCode from 'qrcode.react';

import './TabDetails.css'

const TabDetails = ({ parent, tabId, logout, showQRCode, showCloseTab }) => {
  const { id } = useParams();

  tabId = id ? id : tabId;

  const error = useRef();
  const [errorText, setErrorText] = useState("");

  const navigate = useNavigate();
  const [axios] = useAxios(true, logout);
  const createOrder = useRef();
  const tabletDetails = useRef();
  const closeTab = useRef();
  const [tabTotal, setTabTotal] = useState(0);

  const [tab, setTab] = useState(null);
  
  const refreshTab = () => {
    axios.get('tab/' + tabId, setTab);
  }
  
  const checkCloseTab = () => {
    closeTab.current.open();
  }
  
  const handleCloseTab = (answer) => {
    if (answer === "Yes")
    {
      axios.post('tab/' + tabId + '/end', {}, () => parent ? parent.current.close() : navigate('/tabs/'), onTabCloseError);
    }
  }

  const onTabCloseError = (e) => {
    setErrorText(e);
    error.current.open();
  }

  useEffect(() => {
    refreshTab();
  }, []);

  const showNewOrder = () => {
    createOrder.current.open();
  }

  const showTabletLogin = () => {
    tabletDetails.current.open();
  }

  const formatToGBP = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value);
  };

  const getOutstandingAmount = () => {
    return tab.orders.reduce((acc, order) => acc + order.items.reduce((acc, item) => acc + (item.cost * item.quantity), 0), 0)
  }

  const getOrderAmount = (order) => {
    return order.items.reduce((acc, item) => acc + (item.cost * item.quantity), 0);
  }

  const getOrderSummary = (order) => {
    return <div>
      {order.items.map(x => <p>{x.item.name} x{x.quantity}</p>)}
    </div>
  }

  if (tab === null)
  {
    return <div>Loading...</div>
  }

  return (
    <div style={{width: "90vw"}}>
      <h2>{tab.name}</h2>

      <button onClick={showNewOrder}>Add Items</button> 
      {showQRCode && <button onClick={showTabletLogin}>Print Login</button>}
      {showCloseTab && <button onClick={checkCloseTab}>Close Tab</button>}
      
      <h4>Outstanding - {formatToGBP(getOutstandingAmount())}</h4>

      <div style={{height: "60vh", overflow:"auto"}}>
        <table className="order-history-table" cellSpacing={30}>
          <thead>
              <tr>
                  <th>Date/Time</th>
                  <th>Summary</th>
                  <th>Amount</th>
              </tr>
          </thead>
          <tbody>
              {tab.orders.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)).map((order) => (
              <tr key={order.id}>
                  <td>{moment(order.dateTime).format("DD MMM yyyy HH:mm")}</td>
                  <td>{getOrderSummary(order)}</td>
                  <td>{formatToGBP(getOrderAmount(order))}</td>
              </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal size="fullscreen" ref={createOrder} onClose={refreshTab}>
        {tab !== null && (
          <OrderDetails tab={tab} logout={logout} onSave={() => createOrder.current.close()} />
        )}
      </Modal>

      <Modal size="small" ref={tabletDetails}>
        {tab !== null && 
          <div>
            <br />
            <QRCode value={process.env.REACT_APP_ROOT_URL + "/login/tablet/" + tab.id} />
            <h4>Pin: {tab.password}</h4>
          </div>
        }
      </Modal>
      
      <Confirm ref={closeTab} title="Are you sure you want to close the tab?" buttons={["Yes", "No"]} onAnswer={handleCloseTab} canClose={false} />
      <Confirm ref={error} title={errorText} buttons={["OK"]} onAnswer={() => {}} canClose={false} />
    </div>
  );
};

export default TabDetails;
