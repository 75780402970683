import React, { useState, useEffect, useRef } from 'react';
import useSessionStorage from '../../../useSessionStorage';

import Input from '../../../Input';

import './CrashScoreboard.css';

const CrashScoreboard = ({logout}) => {
    const [players, setPlayers, clearPlayers] = useSessionStorage("cards/crash/players", []);
    const [selectedPlayer, setSelectedPlayer] = useState();
    const nameChangeModal = useRef();

    const setPlayersCount = (e) => {
        let p = [];
        for (let i = 1; i <= e; i++)
        {
            p.push({ id: i, name: "Player " + i, score: 0 });
        }
        setPlayers(p);
    };

    const nameChanged = (e) => {
        if (e === null)
        {
            return;
        }

        const updatedPlayers = players.map(item => {
            if (item.id === selectedPlayer.id) {
                return { ...item, name: e };
            }
            return item;
        });
    
        setPlayers(updatedPlayers);
        setSelectedPlayer(null);
    };
    
    console.log(players);

    if (players.length === 0)
    {
        return <div className="setup">
            <h2>How many players?</h2>
            <div className="setup-buttons">
                <button onClick={() => setPlayersCount(2)}>2</button>
                <button onClick={() => setPlayersCount(3)}>3</button>
                <button onClick={() => setPlayersCount(4)}>4</button>
            </div>
        </div>;
    }

    return <div className="started">
        <button className="reset" onClick={() => setPlayersCount(0)}>Reset</button>
        <div className="players">
        {
            players.sort((a,b) => a.id < b.id).map((player) => {
                return <div><label onClick={() => { setSelectedPlayer(player); nameChangeModal.current.open(player.name); }}>{player.name}</label><button>+</button>{player.score}<button>-</button></div>;
            })
        }
        </div>
        <Input ref={nameChangeModal} onClose={() => {}} title="Change Name" onSave={nameChanged} canClose={false} />
    </div>;
};

export default CrashScoreboard;