import React, { useState, useEffect } from 'react';
import './ProductsList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

const ProductsList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [products, setProducts] = useState([]);

    const formatToGBP = (value) => {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(value);
    };

    useEffect(() => {
      axios.get('products', setProducts);
    }, []);

  return (
    <div>
      <h2>Products</h2>
      <table className="products-table">
        <thead>
            <tr>
                <th>Product</th>
                <th>Cost</th>
                <th>Image</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td></td>
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/configuration/product/new')}>New</button>
                </td>
            </tr>
            {products.map((categories) => {
              return categories.map((product) => (
                <tr key={product.id}>
                    <td>{product.description}</td>
                    <td>{formatToGBP(product.cost)}</td>
                    <td><img src={product.image} style={{width:"40px"}}/></td>
                    <td>
                        <button className="edit-button" onClick={() => navigate('/configuration/product/' + product.id)}>Edit</button>
                    </td>
                </tr>
                ))
              }
            )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsList;
