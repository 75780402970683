import React, { useState, useEffect } from 'react';
import './PlayersList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

const PlayersList = ({ logout }) => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true, logout);

    const [players, setPlayers] = useState([]);

    useEffect(() => {
      axios.get('players', setPlayers);
    }, []);

  return (
    <div className="players-container">
      <h2>Players</h2>
      <table className="players-table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Handicap</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/player/00000000-0000-0000-0000-000000000000')}>New</button>
                </td>
            </tr>
            {players.map((player) => (
            <tr key={player.id}>
                <td>{player.name}</td>
                <td>{player.handicap}</td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/player/' + player.id)}>Edit</button>
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayersList;
