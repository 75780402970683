// src/Home.js

import React, { useEffect, useState } from 'react';
import './MatchTables.css';
import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

import playerIcon from './images/player.png';
import ballIcon from './images/8ball.png';
import matchIcon from './images/match.png';
import compIcon from './images/comp.png';
import productsIcon from './images/products.png';

const Tile = ({ title, image, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{title}</h3>
      <img src={image}></img>
    </div>
  </a>
);

const Match = ({ table, match, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{table.name}</h3>
      { match === null ? (
        <p>No game</p>
      ) : (
        <div>
          <p>{match.home.player.name} ({match.home.score})</p>
          <p>{match.away.player.name} ({match.away.score})</p>
          <p>{table.status}</p>
        </div>
      )}
    </div>
  </a>
);

const MatchTables = ({ logout }) => {
  const navigate = useNavigate();
  const [axios] = useAxios(true, logout);

  const [tables, setTables] = useState([]);

  useEffect(() => {
    axios.get('tables/with-match-summary', setTables);
  }, []);

  return (
    <div className="home-container">
      <h2>Tables</h2>
      <div className="home-container-group">
        { tables.map((table) => {
          return <Match key={table.id} table={table} match={table.match} onclick={() => navigate('/scoreboard/' + table.id)} />
        })}
      </div>
    </div>
  );
};

export default MatchTables;
