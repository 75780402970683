import React, { useState, useEffect } from 'react';
import './TablesList.css'; // Import your CSS file
import useSessionStorage from './useSessionStorage';

import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

const TablesList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [tenant] = useSessionStorage('tenant');

    const [tables, setTables] = useState([]);

    useEffect(() => {
      axios.get('tables', setTables);
    }, []);

  return (
    <div>
      <h2>Tables</h2>
      <table className="table-table">
        <thead>
            <tr>
                { tenant.super ? <th>Tenant</th> : null }
                <th>Name</th>
                <th>Short Code</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/table/00000000-0000-0000-0000-000000000000')}>New</button>
                </td>
            </tr>
            {tables.map((table) => (
            <tr key={table.id}>
                { tenant.super ? <td>{table.tenantId}</td> : null }
                <td>{table.name}</td>
                <td>{table.shortId}</td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/table/' + table.id)}>Edit</button>
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablesList;
