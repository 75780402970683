import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Scoreboard.css'; // Import the CSS file
import useAxios from './useAxios';

import moment from 'moment';

const Tile = ({ title, text, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  </a>
);

const Scoreboard = () => {
  const { id } = useParams();
  const [table, setTable] = useState(null);
  const [match, setMatch] = useState(null);  
  const [timeRemaining, setTimeRemaining] = useState(null);

  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const getTable = async (table) => {
    axios.get('table/' + table + "/match", (data) => { setTable(data); getMatch(data.match.id); });
  }

  const getMatch = async (matchId) => {
    axios.get('match/' + matchId, setMatchAndStartClock);
  };

  const setMatchAndStartClock = (data) => {
    setMatch(data);
  }

  const setSide = async (side, value) => {
    axios.post('matchside/' + side.id, { "score": value }, (data) => getMatch(table.match.id));
  };

  const setScore = (side, delta) => {
    setSide(match[side], match[side].score + delta);
  };

  const handleStart = () => {
    axios.post('match/' + match.id + "/start", {}, (response) => window.location.reload());
  }

  const handleEnd = () => {
    axios.post('match/' + match.id + "/end", {}, (response) => window.location.reload());
  }

  useEffect(() => {
    getTable(id);
  }, []);

  useEffect(() => {
    const matchIntervalId = setInterval(() => {
        if (table !== null && table.match !== null)
        {
            getMatch(table.match.id);
        }
    }, 10000);
  
      // Clean up the interval when the component is unmounted
      return () => { clearInterval(matchIntervalId); };
  }, [table]);


    
  const clockIntervalId = setInterval(() => {
    if (match !== null && match.status === 1 && match.competition !== null && match.competition.matchTimeLimit !== 0 && timeRemaining === null)
    {
          const limit = 55 * 60;
          const seconds = moment().diff(moment(match.dateTime), 'seconds');
          const duration = moment.duration(limit - seconds, 'seconds');

          setTimeRemaining(duration);
    }
  }, 500);

  if (table === null)
  {
    return <div>Loading...</div>;
  }

  if (match === null)
  {
    return (
      <div className="scoreboard-container">
        <div className="scoreboard-create">
          <Tile title="Create Match" text="Create" onclick={() => navigate('/match/new/'+table.id)} />
        </div>
      </div>
    );
  }

  return (
    <div className="scoreboard-container">
      <h2>{table.name} - Race to {match.raceTo} { timeRemaining === null || timeRemaining < 0 ? "" : "- Time Remaining: " + moment.utc(timeRemaining.asMilliseconds()).format('HH:mm:ss')}</h2>
      { match.status == 0 ? (<button className="scoreboard-container-action" onClick={handleStart}>Start</button>) : null }
      { (match.raceTo <= match.home.score || match.raceTo <= match.away.score || (timeRemaining !== null && timeRemaining < 0)) ? (<button className="scoreboard-container-action" onClick={handleEnd}>End</button>) : null }
      <div className="scoreboard-scores">
        <div className="scoreboard-score">
          <h3>{match.home.player.name}{match.home.doubles === null ? "" : " & " + match.home.doubles.name}</h3>
          <h1 className="score">{match.home.score}</h1>
          <div>
            <button disabled={match.status !== 1}  onClick={() => setScore('home', +1)}>+</button>
            <button disabled={match.status !== 1}  onClick={() => setScore('home', -1)}>-</button>
          </div>
        </div>
        <div className="scoreboard-score">
          <h3>{match.away.player.name}{match.away.doubles === null ? "" : " & " + match.away.doubles.name}</h3>
          <h1 className="score">{match.away.score}</h1>
            <button disabled={match.status !== 1} onClick={() => setScore('away', +1)}>+</button>
            <button disabled={match.status !== 1} onClick={() => setScore('away', -1)}>-</button>
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
