import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Competition.css'; // Import the updated styles for the form
import useAxios from '../useAxios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';
import CompetitionKnockout from './Knockout/CompetitionKnockout';
import CompetitionRoundRobin from './RoundRobin/CompetitionRoundRobin';

const Competition = () => {
  const { id } = useParams();
  const knockoutRef = useRef();
  const roundRobinRef = useRef();

  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const [players, setPlayers] = useState(null);
  const [playerToAdd, setPlayerToAdd] = useState(null);
  const [competition, setCompetition] = useState(null);

  const [types, setTypes] = useState([{id: "knockout", name: "Knockout"}, {id: "roundrobin", name: "Round Robin"}]);

  useEffect(() => {
    axios.get('players', setPlayers)
    axios.get('competition/'+id, setCompetition)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompetition((prevcomp) => ({ ...prevcomp, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCompetition((prevcomp) => ({ ...prevcomp, [name]: checked }));
  };

  const handleDateTimeChange = (datetime) => {
    setCompetition((prevcomp) => ({ ...prevcomp, dateTime: datetime }));
  };

  const handleTypeChange = (type) => {
    setCompetition((prevcomp) => ({ ...prevcomp, type: type }));
  };

  const onSave = () => {
    axios.post('competition/' + id, competition, saveTypeSpecifics);
  };

  const saveTypeSpecifics = (response) => {
    if (response.type.id === "knockout" && knockoutRef.current !== null)
    {
      knockoutRef.current.onSave(response, onCancel);
    }
    
    if (response.type.id === "roundrobin" && roundRobinRef.current !== null)
    {
      roundRobinRef.current.onSave(response, onCancel);
    }
  }

  const onCancel = () => {
    navigate('/competitions');
  }

  if (competition === null || players === null)
  {
    return <h1>Loading</h1>;
  }

  return (
    <div className="edit-competition-container">
      <h2>Edit Competition</h2>
      <form>
        <div className="edit-competition-grid">
          <label>Name:</label>
          <input type="text" name="name" value={competition.name} onChange={handleChange} />
          <br />
          <label>Date/Time:</label>
          <DatePicker
            selected={new Date(competition.dateTime)}
            onChange={handleDateTimeChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="dd MMM yyyy HH:mm"
          />
          <br />
        <label>Type: </label>
        { id === "new" ? <Select
          name="type"
          value={competition.type}
          onChange={(option) => handleTypeChange(option)}
          options={types}
          getOptionValue={(option) => option.id}  // Specify the property used for value
          getOptionLabel={(option) => option.name}  // Specify the property used for label
          isSearchable={false} /> : <label>{competition.type.name}<br/></label> }
        <br />
        { 
          competition.type.id === "knockout" ? <CompetitionKnockout ref={knockoutRef} competition={competition} /> :
          competition.type.id === "roundrobin" ? <CompetitionRoundRobin ref={roundRobinRef} competition={competition} /> :
          null
        }
        </div>
        <br />
        <div>
          <button type="button" onClick={onSave} disabled={competition.status !== 0}>
            Save
          </button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Competition;
