import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Match.css'; // Import the updated styles for the form
import useAxios from './useAxios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';

const Match = () => {
  const { id, tableId } = useParams();

  const [match, setMatch] = useState(null);
  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const [players, setPlayers] = useState(null);
  const [tables, setTables] = useState(null);
  const [types, setTypes] = useState([{id: "singles", name: "Singles"}, {id: "doubles", name: "Doubles"}]);
  const [type, setType] = useState(types[0]);

  useEffect(() => {
    axios.get('match/' + id, (data) => { data.status = (tableId === null ? 0 : 1); setMatch(data); });
    axios.get('players', setPlayers);
    axios.get('tables', setTables);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMatch((prevmatch) => ({ ...prevmatch, [name]: value }));
  };

  const handleDateTimeChange = (datetime) => {
    setMatch((prevmatch) => ({ ...prevmatch, dateTime: datetime }));
  };

  const handlePlayerChange = (side, position, player) => {
    setMatch((prevmatch) => ({ ...prevmatch, [side]: { ...prevmatch[side], [position]: player }}));
  };

  const handleTableChange = (table) => {
    setMatch((prevmatch) => ({ ...prevmatch, table: table}));
  };

  const onSave = () => {
    match.status = 1;
    axios.post('match/' + id, match, tableId === null ? onCancel : () => navigate('/scoreboard/' + tableId));
  };

  const onCancel = () => {
    navigate('/matches');
  }

  if (match === null || players === null || tables === null)
  {
    return <h1>Loading</h1>;
  }

  if (tableId !== null && match.table === null)
  {
      handleTableChange(tables.find(x => x.id === tableId));
  }

  return (
    <div className="edit-match-form">
      <h2>Edit Match</h2>
      <form>
        <label>
          Name:
          <input type="text" name="name" value={match.name ?? ""} onChange={handleChange} />
        </label>
        <br />
        <br />
        <label>
          Date/Time:
        </label>
        <br />
          <DatePicker
            selected={new Date(match.dateTime)}
            onChange={handleDateTimeChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="dd MMM yyyy HH:mm"
          />
        <br />
        <br />
        <label>
          Type:
          <Select
            value={type}
            onChange={(option) => setType(option)}
            options={types}
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          />
        </label>
        <br />
        <label>
          Race To:
          <input
            name="raceTo"
            type="number"
            value={match.raceTo}
            onChange={handleChange}
            min={0}  // Optional: Set minimum value
            max={100}  // Optional: Set maximum value
          />
        </label>
        <br />
        <br />
        <label>
          Home:
        </label>
          <Select
            key="home-player1"
            value={match.home?.player}
            onChange={(option) => handlePlayerChange('home', "player", option)}
            options={players.filter(x => x.id !== match.home?.doubles?.id && x.id !== match.away?.player?.id && x.id !== match.away?.doubles?.id)}
            isClearable={true}
            placeholder="Select a player..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          />
          {type.id === "doubles" ? <Select
            key="home-player2"
            value={match.home?.doubles}
            onChange={(option) => handlePlayerChange('home', "doubles", option)}
            options={players.filter(x => x.id !== match.home?.player?.id && x.id !== match.away?.player?.id && x.id !== match.away?.doubles?.id)}
            isClearable={true}
            placeholder="Select a player..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          /> : null }
        <br />
        <label>
          Away:
        </label>
          <Select
            key="away-player1"
            value={match.away?.player}
            onChange={(option) => handlePlayerChange('away', "player", option)}
            options={players.filter(x => x.id !== match.home?.player?.id && x.id !== match.home?.doubles?.id && x.id !== match.away?.doubles?.id)}
            isClearable={true}
            placeholder="Select a player..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          />
          {type.id === "doubles" ? <Select
            key="away-player2"
            value={match.away?.doubles}
            onChange={(option) => handlePlayerChange('away', "doubles", option)}
            options={players.filter(x => x.id !== match.home?.player?.id && x.id !== match.home?.doubles?.id && x.id !== match.away?.player?.id)}
            isClearable={true}
            placeholder="Select a player..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          /> : null }
        <br />
        <label>
          Table:
          <Select
            value={match.table}
            onChange={(option) => handleTableChange(option)}
            options={tables}
            isClearable={true}
            placeholder="Select a table..."
            getOptionValue={(option) => option.id}  // Specify the property used for value
            getOptionLabel={(option) => option.name}  // Specify the property used for label
            isSearchable={false}
          />
        </label>
        <br />
        <br />
        <button type="button" onClick={onSave}>
          Save
        </button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Match;
