import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import './Product.css'; // Import the updated styles for the form
import useAxios from './useAxios';

import CreatableSelect from 'react-select/creatable';

const Product = () => {
  const { id } = useParams();

  const [product, setProduct] = useState(null);
  const [axios] = useAxios(true);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [categories, setCategories] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      setProduct((prevProduct) => ({ ...prevProduct, image: reader.result }));
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = (file) => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    axios.get('product/' + id, setProduct);
    axios.get('products/categories/', setCategories);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  const handleCategoryChange = (category) => {
    console.log(category);
    setProduct((prevmatch) => ({ ...prevmatch, category: category}));
  };
  
  const handleCreateOption = (inputValue) => {
    const newOption = { id: uuidv4(), name: inputValue };
    setCategories([...categories, newOption]);
    handleCategoryChange(newOption);
  };

  const onSave = () => {
    axios.post('product', product, onCancel);
  };

  const onCancel = () => {
    navigate('/configuration/products');
  }

  if (product === null)
  {
    return <h1>Loading</h1>;
  }

  return (
    <div className="edit-product-form">
      <h2>Edit Product</h2>
      <form>
        <label>
          Category:
        </label>
        <CreatableSelect
          value={product.category}
          onChange={(option) => handleCategoryChange(option)}
          options={categories}
          isClearable={true}
          placeholder="Select a category..."
          onCreateOption={handleCreateOption}
          getOptionValue={(option) => option.id}  // Specify the property used for value
          getOptionLabel={(option) => option.name}  // Specify the property used for label
        />
        <br />
        <br />
        <label>
          Description:
        </label>
          <input type="text" name="description" value={product.description} onChange={handleChange} />
        <br />
        <br />
        <label>
          Cost:
        </label>
          <input type="number" name="cost" value={product.cost} onChange={handleChange} />
        <br />
        <br />
        <label>
          Image:
        </label><br/>
          {product.image && <img src={product.image} style={{width:"50vw"}}/>}<br/>
          <input type="file" ref={fileInputRef} onChange={handleFileChange} />
        <br />
        <br />
        <button type="button" onClick={onSave}>
          Save
        </button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Product;
