import { useState } from 'react';

const useSessionStorage = (key, initialValue) => {
  // Retrieve stored value from sessionStorage
  const storedValue = sessionStorage.getItem(key);

  // Set the initial value based on whether a stored value exists
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // State to track the current value
  const [value, setValue] = useState(initial);

  // Update the sessionStorage when the value changes
  const set = (newValue) => {
    try {
      // Save the value to sessionStorage
      sessionStorage.setItem(key, JSON.stringify(newValue));
      // Update the state
      setValue(newValue);
    } catch (error) {
      console.error(`Error updating session storage for key "${key}": ${error.message}`);
    }
  };

  // Remove the item from sessionStorage
  const clear = () => {
    try {
      sessionStorage.removeItem(key);
      setValue(null);
    } catch (error) {
      console.error(`Error clearing session storage for key "${key}": ${error.message}`);
    }
  };

  return [value, set, clear];
};

export default useSessionStorage;