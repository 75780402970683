import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Player.css'; // Import the updated styles for the form
import useAxios from './useAxios';

const Player = () => {
  const { id } = useParams();

  const [player, setPlayer] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [axios] = useAxios(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('player/' + id, setPlayer);
    axios.get('player/' + id, (data) => {
      setPlayer(data);
      setDateOfBirth(data.dateOfBirth || '');
    });
  }, [id, axios]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlayer((prevPlayer) => ({ ...prevPlayer, [name]: value }));
    if (name === 'dateOfBirth') {
      setDateOfBirth(value);
    } else {
      setPlayer((prevPlayer) => ({ ...prevPlayer, [name]: value }));
    }
  };

  const handleDateChange = (e) => {
    setDateOfBirth(e.target.value);
  }

  const onSave = () => {
    axios.post('player/' + id, { ...player, dateOfBirth }, onCancel);
  };

  const onCancel = () => {
    navigate('/players');
  }

  if (player === null)
  {
    return <h1>Loading</h1>;
  }

  return (
    <div className="edit-player-form">
      <h2>Edit Player</h2>
      <form>
        <label>
          Name:
          <input type="text" name="name" value={player.name} onChange={handleChange} />
        </label>
        <br />
        <br />
        <label>
          Handicap:
          <input type="number" name="handicap" value={player.handicap} onChange={handleChange} />
        </label>
        <br />
        <br />
        <br />
        <br />
        <label>
          Date of Birth:
          <input type="date" name="dateOfBirth" value={dateOfBirth} onChange={handleDateChange} />
        </label>
        <br />
        <br />
        <button type="button" onClick={onSave}>
          Save
        </button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Player;