// src/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

const Timer = ({ table, logout }) => {
  const [timer, setTimer] = useState({
    duration: 30,
    start: new Date(),
    stop: false
  });
  const [timerElasped, setTimerElasped] = useState(null);

  useEffect(() => {
    if (timer.stop === false)
    {
        const clockIntervalId = setInterval(() => {
          const minutes = moment().diff(timer.start, 'seconds');
          const duration = moment.duration(minutes, 'seconds');
          let elasped = timer.duration - duration.asSeconds();

          if (elasped < 0)
          {
            elasped = 0;
            timer.stop = true;
          }

          setTimerElasped(elasped);
        }, 500);
  
        // Clean up the interval when the component is unmounted
        return () => { clearInterval(clockIntervalId); };
    }
  }, [timer]);

  const handleTimerReset = () => {
    setTimerElasped(30);
    setTimer({
      duration: 30,
      start: new Date(),
      stop: false
    });
  }

  return (
    <div>
      { 
        <div onClick={handleTimerReset}>
          <div style={{height:"30%"}}>
            <h2 style={{ fontSize:"55vh", lineHeight:"1vh" }} >
              {timerElasped}
            </h2>
          </div>
        </div>
      }
    </div>
  );
};

export default Timer;
