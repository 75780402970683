import React, { useState, useEffect } from 'react';
import './CompetitionsList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import useAxios from '../useAxios';

import moment from 'moment';

const CompetitionsList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [competitions, setCompetitions] = useState([]);

    useEffect(() => {
      axios.get('competitions', setCompetitions);
    }, []);

  return (
    <div className="competitions-container">
      <h2>Competitions</h2>
      <table className="competitions-table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Date/Time</th>
                <th>Type</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/competition/new')}>New</button>
                </td>
            </tr>
            {competitions.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)).map((competition) => (
            <tr key={competition.id}>
                <td>{competition.name}</td>
                <td>{moment(competition.dateTime).format("DD/MM/yyyy HH:mm")}</td>
                <td>{competition.type?.name}</td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/competition/' + competition.id)}>Edit</button>
                    <button className="edit-button" onClick={() => navigate('/competition/' + competition.id + '/draw')}>Draw</button>
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompetitionsList;
