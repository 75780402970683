import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';

import './CompetitionKnockout.css'; // Import the updated styles for the form
import useAxios from '../../useAxios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';

const CompetitionKnockout = forwardRef(({ competition }, ref) => {

  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const [players, setPlayers] = useState(null);
  const [playerToAdd, setPlayerToAdd] = useState(null);
  const [knockout, setKnockout] = useState(null);

  useEffect(() => {
    axios.get('players', setPlayers)
    axios.get('competitionknockout/'+(competition.id??"new"), setKnockout)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKnockout((prevcomp) => ({ ...prevcomp, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setKnockout((prevcomp) => ({ ...prevcomp, [name]: checked }));
  };

  const handleDateTimeChange = (datetime) => {
    setKnockout((prevcomp) => ({ ...prevcomp, dateTime: datetime }));
  };

  const addPlayer = () => {
    setKnockout((prevcomp) => ({ ...prevcomp, players: [...prevcomp.players, playerToAdd]}));
    setPlayerToAdd(null);
  };

  const removePlayer = (p) => {
    setKnockout((prevcomp) => ({ ...prevcomp, players: prevcomp.players.filter(x => x.id !== p.id)}));
  };
  
  const handleOnSave = (competition, next) => {
    knockout.competitionId = competition.id;
    axios.post('competitionknockout/' + competition.id, knockout, next);
  };

  // Expose the function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    onSave: handleOnSave,
  }));

  if (knockout === null || players === null)
  {
    return <h1>Loading</h1>;
  }

  return (
        <div className="edit-knockout-grid">
          <div className="col1">
            <label>Race To:</label>
            <br />
            <input
                name="raceTo"
                type="number"
                value={knockout.raceTo}
                onChange={handleChange}
                min={0}  // Optional: Set minimum value
                max={100}  // Optional: Set maximum value
              />
            <br />
            <br />
            <label>Match Time Limit:</label>
            <br />
            <input
                name="matchTimeLimit"
                type="number"
                value={knockout.matchTimeLimit}
                onChange={handleChange}
                min={0}  // Optional: Set minimum value
                max={300}  // Optional: Set maximum value
              />
            <br />
            <br />
            <label>Handicapped:</label>
            <br />
            <input
                name="handicapped"
                type="checkbox"
                value={knockout.handicapped}
                checked={knockout.handicapped}
                onChange={handleCheckboxChange}
              />
              <br />
              <br />
            <label>Players:</label>
            <Select
              value={playerToAdd}
              onChange={(option) => setPlayerToAdd(option)}
              options={players.filter(x => knockout.players.some(y => y.name === x.name) === false)}
              isClearable={true}
              placeholder="Select a player..."
              getOptionValue={(option) => option.id}  // Specify the property used for value
              getOptionLabel={(option) => option.name}  // Specify the property used for label
              isSearchable={false}
            />
            <button type="button" onClick={addPlayer} disabled={playerToAdd === null}>
              Add
            </button>
            {knockout.players.map(x => { return (
              <div style={{height:"50px"}} key={x.id}>{x.name} ({x.handicap}) <a className="small-cross" onClick={() => removePlayer(x)}>x</a></div>
            )})}
          </div>
        </div>
  );
});

export default CompetitionKnockout;
