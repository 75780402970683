import React, { useState, useEffect} from 'react';
import './CompetitionKnockoutDraw.css'

import Select from 'react-select';

import moment from 'moment';

import useAxios from '../../useAxios';

const CompetitionKnockoutDraw = ({competition, readonly, logout}) => {
  const [knockout, setKnockout] = useState(null);
  const [tables, setTables] = useState(null);
  const [disableDraw, setDisableDraw] = useState(false);
  const [axios] = useAxios(true, logout);

  useEffect(() => {
    getKnockout();
    axios.get("tables", setTables)
  }, []);

  const getKnockout = () => {
    if (readonly)
    {
      axios.get("public/competition/knockout/" + competition.id, setKnockout);
    }
    else
    {
      axios.get("competitionknockout/" + competition.id, setKnockout);
    }
  };

  const handleTableChange = (table, match) => {
    match.table = table;
    axios.post("match/" + match.id, match, getKnockout)
  }
  
  const handleDraw = () => {
    setDisableDraw(true);
    axios.post("competitionknockout/" + competition.id + "/draw", {}, (response) => window.location.reload())
  };

  const handleStart = () => {
    axios.post("competition/" + competition.id + "/start", {}, (response) => window.location.reload())
  };

  const handleEnd = () => {
    axios.post("competition/" + competition.id + "/end", {}, (response) => window.location.reload())
  };

  const getMatchDescription = (gameNumber, side) => {
    let game = null;

    for (let i = 0; i < knockout.rounds.length && game === null; i++)
    {
      var g = knockout.rounds[i].matches.filter(x => x.gameNumber === gameNumber);
      if (g.length > 0)
      {
        game = g[0];
      }
    }

    if (game != null)
    {
      var match = game[side].match;
      if (match != null && match.status === 2)
      {
        return match.home.winner ? match.home.player.name : match.away.player.name;
      }

      return "Winner of Match " + game[side].gameNumber;
    }

    return "something went wrong";
  } 

  if (knockout === null)
  {
    return <div>Loading...</div>
  }

  return (
    <div>
      {
        readonly == false && competition.status == 0 ?
        <button onClick={handleDraw} disabled={disableDraw}>{knockout.rounds.length > 0 ? "Re-" : ""}Draw</button> :
        null
      }
      {
        readonly == false && competition.status === 0 ?
        <button onClick={handleStart}>Start</button> :
        readonly == false && competition.status === 1 ?
        <button onClick={handleEnd}>End</button> :
        null
      }
      { 
        knockout.rounds.map((round, roundNumber) => {
            return (<div key={round.id}>
              <h2>{round.name} - Race to {knockout.raceTo}</h2>
              <table>
                <tbody>
                { 
                  round.matches.map((m) => {
                    if (m.match == null)
                    {
                      return (<tr key={m.id}>
                        <td>{m.gameNumber}</td>
                        <td>{m.dateTime === null ? "" : moment(m.estimatedDateTime).format("HH:mm")}</td>
                        <td>{getMatchDescription(m.gameNumber, "home")}</td>
                        <td></td>
                        <td>v</td>
                        <td></td>
                        <td>{getMatchDescription(m.gameNumber, "away")}</td>
                        <td></td>
                      </tr>);
                    }
                    
                    return (<tr key={m.id} className={m.match.status === 2 ? "finished" : ""}>
                      <td>{m.gameNumber}</td>
                      <td>{m.match.dateTime === null ? "" : moment(m.match.dateTime).format("HH:mm")}</td>
                      <td>{m.match.home.player === null ? "Bye" : m.match.home.player.name + " (" + m.match.home.player.handicap + ")"}</td>
                      <td>{m.match.home.score}</td>
                      <td>v</td>
                      <td>{m.match.away.score}</td>
                      <td>{m.match.away.player === null ? "Bye" : "(" + m.match.away.player.handicap + ") " + m.match.away.player.name}</td>
                      <td>
                        {readonly == false && m.match.status != 2 ? <Select
                          value={m.match.table}
                          onChange={(option) => handleTableChange(option, m.match)}
                          options={tables}
                          isClearable={true}
                          placeholder="Select..."
                          getOptionValue={(option) => option.id}  // Specify the property used for value
                          getOptionLabel={(option) => option.name}  // Specify the property used for label
                          isSearchable={false}
                        /> : null }
                      </td>
                    </tr>);
                  })
                }
                </tbody>
              </table>
            </div>);
        })
      }
    </div>
  );
};

export default CompetitionKnockoutDraw;
