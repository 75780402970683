// src/Home.js

import React, { useEffect, useState } from 'react';
import './ConfigurationDashboard.css';
import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

import playerIcon from './images/player.png';
import ballIcon from './images/8ball.png';
import matchIcon from './images/match.png';
import productsIcon from './images/products.png';

const Tile = ({ title, image, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{title}</h3>
      <img src={image}></img>
    </div>
  </a>
);

const ConfigurationDashboard = ({ logout }) => {
  const navigate = useNavigate();
  const [axios] = useAxios(true, logout);

  useEffect(() => {
  }, []);

  return (
    <div className="home-container">
      <h2>Configuration</h2>
      <div className="home-container-group">
        <Tile title="Players" image={playerIcon} onclick={() => navigate('/configuration/players')} />
        <Tile title="Tables" image={ballIcon} onclick={() => navigate('/configuration/tables')} />
        <Tile title="Matches" image={matchIcon} onclick={() => navigate('/configuration/matches')} />
        <Tile title="Products" image={productsIcon} onclick={() => navigate('/configuration/products')} />
      </div>
    </div>
  );
};

export default ConfigurationDashboard;
