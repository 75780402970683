import useSessionStorage from './useSessionStorage';

import { useNavigate } from 'react-router-dom';

const useAxios = (requiresAuth, on401) => {
  const [token, setToken] = useSessionStorage('token', '');
  const navigate = useNavigate();

  const headers = {
    'Content-Type': 'application/json'
  }

  if (requiresAuth === true)
  {
    headers['Authorization'] = 'bearer ' + token;
  }

  const get = async (api, onsuccess, onerror) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + api, {
        method: 'GET',
        headers: headers,
      });

      if (response.ok) {
        const data = await response.json();
        if (onsuccess)
        {
          onsuccess(data);
        }
      } else {
        if (response.status === 401)
        {
            on401();
        }

        if (onerror)
        {
          onerror();
        }
      }
    } catch (error) {
      console.log(error);
      if (onerror)
      {
        onerror(error);
      }
    }
  };
  const post = async (api, body, onsuccess, onerror) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + api, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        if (onsuccess)
        {
           onsuccess(data);
        }
      } else {
        if (response.status === 401)
        {
          on401();
        }

        if (onerror)
        {
          onerror(await response.text());
        }
      }
    } catch (error) {
      if (onerror)
      {
        onerror(error);
      }
    }
  };

  const wrapper = {
      get: get,
      post: post
  }

  return [wrapper];
};

export default useAxios;