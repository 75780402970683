import React from 'react';

const FacebookShareButton = ({ url, quote }) => {
  const handleShare = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?p[url]=${encodeURIComponent(url)}&p[title]=${encodeURIComponent(quote)}&p[summary]=${encodeURIComponent(quote)}`;
    window.open(facebookShareUrl, '_blank');
  };

  return (
    <button onClick={handleShare}>Share on Facebook</button>
  );
};

export default FacebookShareButton;