import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './TabletLogin.css';

import useAxios from './useAxios';

import PinPad from './PinPad'

const TabletLogin = ({ onLogin }) => {
  const { tabId } = useParams();
  const [axios] = useAxios();

  const handleTabletLogin = (pin, success, error) => {
    setTimeout(() => {
      axios.post('auth/tab', { id: tabId, password: pin }, onLogin, error);
    }, 1000);
  }
  
  return (
    <div className="tablet-login-container">
      <div className="tablet-login-form">
        <PinPad onPinSubmit={handleTabletLogin} />
      </div>
    </div>
  );
};

export default TabletLogin;
