import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

import './Modal.css'

const Modal = forwardRef(({ size, children, onClose, canClose }, ref) => {
  const [open, setOpen] = useState(false);

  // Expose the function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => { setOpen(false); onClose && onClose(); } 
  }));

  if (open === false)
  {
    return;
  }
  
  return (
    <div className="modal-overlay">
      <div className={"modal " + size}>
        { canClose !== false && <button onClick={ref.current.close} className="close-button">
          Close
        </button> }
        { children }
      </div>
    </div>
  );
});

export default Modal;
