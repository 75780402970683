import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useSessionStorage from './useSessionStorage';

import './Table.css'; // Import the updated styles for the form
import useAxios from './useAxios';

const Table = () => {
  const { id } = useParams();

  const [tenant] = useSessionStorage('tenant');
  const [table, setTable] = useState(null);
  const [axios] = useAxios(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('table/' + id, setTable);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTable((prevTable) => ({ ...prevTable, [name]: value }));
  };

  const onSave = () => {
    axios.post('table/' + id, table, onCancel);
  };

  const onCancel = () => {
    navigate('/tables');
  }

  if (table === null)
  {
    return <h1>Loading</h1>;
  }

  return (
    <div className="edit-table-form">
      <h2>Edit Table</h2>
      <form>
        <label>
          Name:
          <input type="text" name="name" value={table.name} onChange={handleChange} />
        </label>
        <br />
        <br />
        <label>
          Short Code:
          <input type="text" name="shortId" value={table.shortId} onChange={handleChange} />
        </label>
        <br />
        <br />
        <button type="button" onClick={onSave}>
          Save
        </button>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Table;
