import React, { useState } from 'react';

import './PinPad.css'

import useAxios from './useAxios';

const PinPad = ({ onPinSubmit }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [axios] = useAxios();

  const handleButtonClick = (value) => {
    setError(null);
    setPin(pin + value);
  };

  const handleBackspace = () => {
    setError(null);
    if (pin.length > 0)
    {
      setPin(pin.slice(0, -1));
    }
  };

  const handleError = (e) => {
    console.log(e)

    setPin("");
    setDisabled(false);
    setError("Login Failed");
  }

  const handleSubmit = () => {
    setDisabled(true);
    onPinSubmit(pin, (result) => {}, handleError);
  }
  
  return (
    <div className="pin-pad-container">
      <div className="pin-display">{error === null ? pin.replace(/./g, '*') : error}</div>
      <div className="pin-pad-keys">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
          <button disabled={disabled} key={number} onClick={() => handleButtonClick(number)}>
            {number}
          </button>
        ))}
        <button disabled={disabled} onClick={handleBackspace}>&larr;</button>
        <button disabled={disabled} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default PinPad;