import React, { useState, useEffect} from 'react';
import useAxios from '../../useAxios';

import Dartboard from './Dartboard';

const DartsScoreboard = ({logout}) => {
    function handleClick(event) {
        console.log(event.target.id);
    }

    return <div style={{marginTop: "50px"}}>
        <Dartboard onclick={handleClick} />
    </div>;
};

export default DartsScoreboard;