import React, { useState, useEffect} from 'react';
import './CompetitionRoundRobinDraw.css'

import Select from 'react-select';

import moment from 'moment';

import useAxios from '../../useAxios';

const CompetitionRoundRobinDraw = ({competition, readonly, logout}) => {
  const [roundRobin, setRoundRobin] = useState(null);
  const [tables, setTables] = useState(null);
  const [axios] = useAxios(true, logout);
  const [disableDraw, setDisableDraw] = useState(false);

  useEffect(() => {
    getRoundRobin();
    if (readonly === false)
    {
      axios.get("tables", setTables);
    }
  }, []);

  const getRoundRobin = () => {
    if (readonly)
    {
      axios.get("public/competition/roundrobin/" + competition.id, setRoundRobin);
    }
    else
    {
      axios.get("competitionroundrobin/" + competition.id, setRoundRobin);
    }
  };

  const handleTableChange = (table, match) => {
    match.table = table;
    axios.post("match/" + match.id, match, getRoundRobin)
  }
  
  const handleDraw = () => {
    setDisableDraw(true);
    axios.post("competitionroundrobin/" + competition.id + "/draw", {}, (response) => window.location.reload())
  };

  const handleStart = () => {
    axios.post("competition/" + competition.id + "/start", {}, (response) => window.location.reload())
  };

  const handleEnd = () => {
    axios.post("competition/" + competition.id + "/end", {}, (response) => window.location.reload())
  };

  const group = (xs, key) => {
    return Object.values(xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {}));
  };

  const getMatchDescription = (gameNumber, side) => {
    let game = null;

    for (let i = 0; i < roundRobin.rounds.length && game === null; i++)
    {
      var g = roundRobin.rounds[i].matches.filter(x => x.gameNumber === gameNumber);
      if (g.length > 0)
      {
        game = g[0];
      }
    }

    if (game != null)
    {
      var match = game[side].match;
      if (match != null && match.status === 2)
      {
        return match.home.winner ? match.home.player.name : match.away.player.name;
      }

      return "Winner of Match " + game[side].gameNumber;
    }

    return "something went wrong";
  } 

  if (roundRobin === null)
  {
    return <div>Loading...</div>
  }
  
  return (
    <div>
      {
        readonly == false && competition.status == 0 ?
        <button onClick={handleDraw} disabled={disableDraw}>{roundRobin.rounds.length > 0 ? "Re-" : ""}Draw</button> :
        null
      }
      {
        readonly == false && competition.status === 0 ?
        <button onClick={handleStart}>Start</button> :
        readonly == false && competition.status === 1 ?
        <button onClick={handleEnd}>End</button> :
        null
      }
      <br />
      <br />
      { roundRobin.tables.map(table => { return (
        <div className="competition-roundrobin-table">
          <h2>Group {table[0].group}</h2>
          <table>
            <thead>
              <tr>
                <td style={{backgroundColor: "#f4f4f4", borderTop: "none", borderLeft: "none"}}></td>
                <td>Played</td>
                <td>Won</td>
                <td>Lost</td>
                <td>Draw</td>
                <td>Frames</td>
                <td>Points</td>
              </tr>
            </thead>
            <tbody>
              {
                table.sort((a,b) => b.points - a.points).map((row) => {
                  return (<tr>
                    <th>{row.player.name}</th>
                    <td>{row.played}</td>
                    <td>{row.won}</td>
                    <td>{row.lost}</td>
                    <td>{row.draw}</td>
                    <td>{row.frames}</td>
                    <td>{row.points}</td>
                  </tr>);
                })
              }
            </tbody>
          </table>
        </div>
      )})
      }
      { 
        group(roundRobin.rounds.sort((a, b) => a.roundNumber - b.roundNumber), 'roundNumber').map((group, roundNumber) => {
            return (<div key={group.id}>
              <h2>Round {roundNumber + 1} - Race to {roundRobin.raceTo}</h2>
              <table>
                <tbody>
                { 
                  group.map(round => { return round.matches.map((m) => {
                    
                    return (<tr key={m.id} className={m.match.status === 2 ? "finished" : ""}>
                      <td>Group {round.group}</td>
                      <td>{m.gameNumber}</td>
                      <td>{m.match.home?.player === null ? "Bye" : m.match.home?.player?.name + " (" + m.match.home?.player?.handicap + ")"}</td>
                      <td>{m.match.home?.score}</td>
                      <td>v</td>
                      <td>{m.match.away?.score}</td>
                      <td>{m.match.away?.player === null ? "Bye" : "(" + m.match.away?.player?.handicap + ") " + m.match.away?.player?.name}</td>
                      <td>
                        {readonly === false && m.match.status != 2 ? <Select
                          value={m.match.table}
                          onChange={(option) => handleTableChange(option, m.match)}
                          options={tables}
                          isClearable={true}
                          placeholder="Select..."
                          getOptionValue={(option) => option.id}  // Specify the property used for value
                          getOptionLabel={(option) => option.name}  // Specify the property used for label
                          isSearchable={false}
                        /> : m.match?.table?.name }
                      </td>
                    </tr>);
                  }) })
                }
                </tbody>
              </table>
            </div>);
        })
      }
    </div>
  );
};

export default CompetitionRoundRobinDraw;
