import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './ScoreboardOverlay.css'; // Import your CSS file

import useAxios from './useAxios';

import ajb from './images/ajb.png';
import youtube from './images/youtube.png';

const ScoreboardOverlay = () => {
    const { id } = useParams();
    const [match, setMatch] = useState(null);
    const [axios] = useAxios(true);
    
    useEffect(() => {
      getMatch(id);
    }, []);
    
  useEffect(() => {
    const intervalId = setInterval(() => {
        getMatch(id);
    }, 10000);
  
      // Clean up the interval when the component is unmounted
      return () => clearInterval(intervalId);
  }, [match]);

  const getMatch = async (id) => {
    axios.get('overlay/' + id, setMatch);
  };

if (match == null)
{
    return (
        <div className="scoreboard-overlay">
            <div className="vs">No Information</div>
        </div>
    );
}

  return (
    <div className="scoreboard-overlay">
        <div class="bottom-bar">
          <div className="bottom-image">
            <img style={{height:"9vh"}} src={ajb} />
          </div>
          <div>
            <div className="score-container">
                <div className="player home">{match.home.player.name}</div>
                <div className="score">{match.home.score}</div>
                <div className="vs">v</div>
                <div className="score">{match.away.score}</div>
                <div className="player away">{match.away.player.name}</div>
            </div>
            <div className="match-summary">
              Race to {match.raceTo}
            </div>
          </div>
          <div className="bottom-image">
            <img style={{height:"13vh"}} src={youtube} />
          </div>
        </div>
    </div>
  );
};

export default ScoreboardOverlay;
