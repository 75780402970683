import React, { useState, useEffect} from 'react';
import './CompetitionDraw.css'

import Select from 'react-select';

import moment from 'moment';

import useAxios from '../useAxios';
import { useParams, useNavigate } from 'react-router-dom';

import FacebookShareButton from '../FacebookShareButton';
import CompetitionKnockoutDraw from './Knockout/CompetitionKnockoutDraw';
import CompetitionRoundRobinDraw from './RoundRobin/CompetitionRoundRobinDraw';

const CompetitionDraw = ({logout, readonly}) => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [axios] = useAxios(true, logout);

  useEffect(() => {
    axios.get("competition/" + id, (comp) => { setCompetition(comp); })
  }, []);

  if (competition === null)
  {
    return <div>Loading...</div>
  }

  document.title = competition.name;

  return (
    <div className={"competition-container " + (readonly ? "no-nav" : "")}>
      <h1>{competition.name}</h1>
      { readonly ? null : <FacebookShareButton url={process.env.REACT_APP_ROOT_URL + "/public/competition/" + competition.id} quote={competition.name} /> }
      { 
        competition.type.id === "knockout" ? <CompetitionKnockoutDraw competition={competition} readonly={readonly} logout={logout} /> :
        competition.type.id === "roundrobin" ? <CompetitionRoundRobinDraw competition={competition} readonly={readonly} logout={logout} /> :
        null
      }
    </div>
  );
};

export default CompetitionDraw;
